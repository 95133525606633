import { Container, LoaderWheel } from './styles';

interface LoadingProps {
}

function Loading({}: LoadingProps) {
  return (
    <Container>
      <LoaderWheel />
    </Container>
  );
};

export default Loading;
