import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt, FaFilePdf } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { b64toBlob } from "../../utils/base62";
import { anos, meses } from "../../utils/datas";
import { getDateFromString } from "../../utils/date";
import { DateInfo } from "../ShareCapital/styles";

import { Container, Filters, InputGroup } from "./styles";

interface ClosingStatementProps {}

function ClosingStatement({}: RouteComponentProps<ClosingStatementProps>) {
  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [fechamentos, setFechamentos] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [anomes, setAnomes] = useState(
    `${new Date().getFullYear()}0${new Date().getMonth() + 1}`
  );

  useEffect(() => {
    setLoading(true);
    api
      .get("getListaFechamento", {
        params: {
          CODCLIENTE: user.matricula,
          ANOMES: anomes,
        },
      })
      .then((res: any) => {
        setFechamentos(res.data.fechamentos);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.matricula, anomes]);

  async function getPdf(tipo: string) {
    setLoadingPdf(true);
    let newTab = window.open()
    newTab.document.write('Carregando...');
    newTab.document.title = "Carregando..."
    try {
      const res = await api.get<any>("getFechamento", {
        params: {
          CODCLIENTE: user.matricula,
          ANOMES: anomes,
          TIPOFCH: tipo,
        },
      });
      if (!res.data.pdf) throw new Error("Erro ao requisitar pdf");
      const blob = b64toBlob(res.data.pdf, "application/pdf");
      const url = URL.createObjectURL(blob);
      newTab.location.href = url
    } catch (err) {
      alert(err.message);
      newTab.close();
    } finally {
      setLoadingPdf(false);
    }
  }

  function submitForm(form) {
    
    setAnomes(`${form.ano}${form.mes}`);
  }

  return (
    <Container>
      <PageHeader title="Extrato de Fechamento" />

      <SubTitle text="Informe o período:" icon={FaCalendarAlt} />
      <Filters>
        <InputGroup>
          <Input
            control={control}
            type="select"
            values={meses}
            name="mes"
            placeholder="Mês"
          />
        </InputGroup>
        <InputGroup>
          <Input
            control={control}
            type="select"
            values={anos}
            name="ano"
            placeholder="Ano"
          />
        </InputGroup>
        <InputGroup>
          <Button onClick={handleSubmit(submitForm)}>CONSULTAR</Button>
        </InputGroup>
      </Filters>

      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>
            <Table>
              <tr>
                <th>Descrição do Fechamento</th>
                <th>Linha de Crédito</th>
                <th>Extrato</th>
              </tr>
              {fechamentos.map((fechamento) => (
                <tr>
                  <td>{fechamento.descfechamento}</td>
                  <td>{fechamento.linhacredito}</td>
                  <td>
                    {loadingPdf ? (
                      <Loading />
                    ) : (
                      <button onClick={() => getPdf(fechamento.tipo)}>
                        <FaFilePdf />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </Table>
          </>
        )}
      </Card>
    </Container>
  );
}

export default ClosingStatement;
