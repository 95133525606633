import styled from "styled-components";
import { devicesMax } from "../../utils/responsive"
export const Container = styled.div`

@media ${devicesMax.tablet}{
  &.div-table{
    overflow-x:auto;
  }
}

`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: #1f2f46;
  margin-bottom: 5px;
`;

export const Content = styled.a`
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #505050;
  cursor: pointer;
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;

export const Files = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
`;

export const Td = styled.td`
  text-align: right;
  @media ${devicesMax.mobileL}{
    text-align: left;
  }
`;