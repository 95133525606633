export function getDateFromString(dateString: string) {
  return new Date(
    +dateString.substr(0, 4),
    +dateString.substr(4, 2) - 1,
    +dateString.substr(6, 2)
  );
}

export function getMonthCapilized(date: Date) {
  const dateString = date.toLocaleString("default", { month: "long" });
  return dateString.charAt(0).toUpperCase() + dateString.slice(1);
}

export function getStringFromDateString(dateString: string) {
  const dateArray = dateString.split("/")
  const date = new Date(+dateArray[2], +dateArray[1]-1, +dateArray[0])
  let vencdeMonth = `${date.getMonth()+1}`
  vencdeMonth = vencdeMonth.length === 1 ? `0${vencdeMonth}` : `${vencdeMonth}`
  let vencdeDay = `${date.getDate()}`
  vencdeDay = vencdeDay.length === 1 ? `0${vencdeDay}` : `${vencdeDay}`
  return `${date.getFullYear()}${vencdeMonth}${vencdeDay}`
}