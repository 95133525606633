import styled from "styled-components";

import { devicesMax } from "../../utils/responsive"


export const Container = styled.div`

@media ${devicesMax.tablet}{
  &.div-table{
    overflow-x:auto;
  }
}
`;

export const DateInfo = styled.p`
  font-size: 1.8rem;
  color: #b4b4b4;
  font-weight: 700;

  @media ${devicesMax.mobileL}{
    font-size: 1.3rem;
  }
`;

export const Filters = styled.div`
  display: flex;

  @media ${devicesMax.mobileL}{
    display: block;
  }
`;

export const InputGroup = styled.div`
  margin: 15px 15px 15px 0;
`;

