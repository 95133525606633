import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"
export const Container = styled.div`

`;

export const Form = styled.form``

export const Row = styled.div`
  display: flex;

  @media ${devicesMax.mobileL}{
    display: block;
  }
`

export const InputGroup = styled.div`
  flex: 1;
  margin: 15px 15px 15px 0;

 
`;