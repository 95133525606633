import { ReactNode } from 'react';

import { Container } from './styles';

interface TableProps extends React.HTMLAttributes<HTMLTableElement>{
  children: ReactNode;
}

function Table({ children, ...rest }: TableProps) {
  return (
    <Container {...rest}>
      {children}
    </Container>
  );
};

export default Table;
