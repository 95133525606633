import styled from "styled-components";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import { devicesMax } from "../../../utils/responsive"

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.p`
  color: #6c6c6c;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 5px;

  @media ${devicesMax.mobileL} {
    font-size: 1.2rem;
  }
  @media ${devicesMax.mobileM} {
    font-size: 1.1rem;
  }
  @media ${devicesMax.mobileS} {
    font-size: 1rem;
  }
`;

export const InputText = styled.input`
  height: 50px;
  width: 100%;
  /* background-color: #F6F6F6; */
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #e8e8e8;
  
`;

export const InputTextArea = styled.textarea`
  height: 150px;
  width: 100%;
  /* background-color: #F6F6F6; */
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 8px;
  padding: 16px 16px 0px 16px;
  border: 1px solid #e8e8e8;
`;

export const InputCurrencyText = styled(NumberFormat)`
  height: 50px;
  width: 100%;
  /* background-color: #F6F6F6; */
  border-width: 1px;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #e8e8e8;
`;

export const Error = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.danger};
  margin: 7px 0;
`;

export const InputSelect = styled.select`
  height: 50px;
  width: 100%;
  min-width: 200px;
  border-color: #e8e8e8;
  border-width: 1px;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #e8e8e8;
`;

export const InputMaskedText = styled(InputMask)`
  height: 50px;
  width: 100%;
  border-width: 1px;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #e8e8e8;
`;
