import { IconType } from 'react-icons';
import { Container, Text } from './styles';

interface SubTitleProps {
  text: string;
  icon?: IconType;
  sub?: boolean;
}

function SubTitle({ text, icon: Icon, sub }: SubTitleProps) {
  return (
    <Container>
      {Icon &&
        <Icon color="#1F2F46" size={20} />
      }
      <Text sub={sub}>{text}</Text>
    </Container>
  );
};

export default SubTitle;
