import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  margin-bottom: 15px;
`

export const InputGroup = styled.div`
  margin-bottom: 10px;
`

export const LoginCard = styled.div`
  flex: 0 0 66.6666666667%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 1px;
  border-color: #d8dbe0;
  background-color: white;
  border-radius: 3px;
`

export const ListaCartoes = styled.div`
  display: flex;
  flex-direction: column;
`

export const Cartao = styled.div`
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  border: 1px solid #d8dbe0;
  padding: 3px;
  border-radius: 4px;
`

export const InfoCartao = styled.div`
  display: flex;
  color: #007433;
  &:hover{
    color: #1F2F46;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const ForgotPasswordText = styled.button`
  font-size: 1.6rem;
`