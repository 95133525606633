import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"
interface MenuProps {
  isOpenMenu: boolean;
}

export const Container = styled.div<MenuProps>`
  transition: all 0.5s ease;
  width: ${({isOpenMenu}) => isOpenMenu ? '310px' : '70px'};
  background-color: #1F2F46;
  padding: 4.0rem 2.7rem;
  paddin-right: 2.7rem;
  display: flex;
  flex-direction: column;
  @media ${devicesMax.tablet} {
    width: ${({isOpenMenu}) => isOpenMenu ? '100vw' : '50px'};
    overflow: ${({isOpenMenu}) => isOpenMenu ? 'hidden' : ''};
    padding: ${({isOpenMenu}) => isOpenMenu ? '2.0rem 0.9rem' : '4.0rem 0.9rem;'};
    align-items: ${({isOpenMenu}) => isOpenMenu ? '' : 'center'};
    position: ${({isOpenMenu}) => isOpenMenu ? 'absolute' : ''};
  }
`;

export const Separator = styled.div`
  background-color: #D0D0D0;
  width: 100%;
  height: 2px;
  background: rgb(208,208,208);
  background: linear-gradient(90deg, rgba(208,208,208,1) 0%, rgba(208,208,208,0) 100%);
  margin: 20px 0px;

  @media ${devicesMax.mobileL} {
    width: 60%;
    background-color: #D0D0D0;
  }
`;