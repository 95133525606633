import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import { useAuth } from "../../hooks/auth";
import { api, apiLogin } from "../../services/api";
import { FaArrowLeft, FaUser } from "react-icons/fa";

import {
  Cartao,
  Container,
  InfoCartao,
  InputGroup,
  ListaCartoes,
  LoginCard,
  Title,
  Header,
  ForgotPassword,
  ForgotPasswordText,
} from "./styles";
import SubTitle from "../../components/SubTitle";

interface LoginProps {}
interface FormData {
  login: string;
  password: string;
  matricula: string;
}

function Login({}: LoginProps) {
  const { signIn, loading } = useAuth();
  const [cartoesativos, setCartoesativos] = useState<any>([]);
  const [loadingCartao, setLoadingCartao] = useState(false);
  const [isSelectedCartao, setIsSelectedCartao] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const submitForm = async (form: FormData) => {
    try {
      const { login, password, matricula } = form;
      try {
        var res = await signIn(login.trim(), password, matricula.trim());
      } catch (e) {
        alert(e.message);
      }
    } catch {
      alert("Não foi possível realizar o login");
    }
  };

  const submitMatricula = async (form: any) => {
    try {
      setLoadingCartao(true);
      api
        .get("getCartoesAtivos", {
          params: {
            CODCLIENTE: form.matricula.toUpperCase(),
          },
        })
        .then((res: any) => {
          setCartoesativos(res.data.cartoesativos);
        })
        .catch(err => {
          if(err.response?.status === 400) {
            setError("matricula", {
              message: "Nenhum cartão vinculado com esta matrícula.",
            });
          } else {
            alert("Não foi possível realizar a requisição.")
          }
        })
        .finally(() => {
          setLoadingCartao(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickCartao = (cartao: string) => {
    setValue("login", cartao);
    setIsSelectedCartao(true);
  };

  const handleClickBack = () => {
    setIsSelectedCartao(false);
    setCartoesativos([]);
  };

  const handleClickForgot = () => {
    setIsSelectedCartao(false);
    setIsForgotPassword(true);
    setCartoesativos([]);
  }

  const submitForgotPassword = async (form: any) => {
    try{
      setLoadingCartao(true);
      await apiLogin.post('/forgotPassword', { email: form.email })
      alert('Email para recuperação de senha enviado no email')
      setIsSelectedCartao(false);
      setIsForgotPassword(false);
    } catch(error){
      alert('Algo deu errado. O email correto?')
    } finally {
      setLoadingCartao(false)
    }
  }

  return (
    <Container>
      <LoginCard>
        <Header>
          <Title>Capul</Title>
          {(!!cartoesativos.length || isSelectedCartao) && (
            <FaArrowLeft
              style={{ cursor: "pointer" }}
              size={20}
              onClick={handleClickBack}
            />
          )}
        </Header>

        {!cartoesativos.length && !isSelectedCartao && !isForgotPassword && (
          <>
            <InputGroup>
              <Input
                control={control}
                name="matricula"
                label="Matrícula"
                error={errors.matricula && errors.matricula.message}
              />
            </InputGroup>
            <Button
              onClick={handleSubmit(submitMatricula)}
              disabled={loadingCartao}
            >
              {loadingCartao ? <Loading /> : "Enviar"}
            </Button>
          </>
        )}

        {!!cartoesativos.length && !isSelectedCartao && (
          <ListaCartoes>
            {cartoesativos?.map((cartao) => (
              <Cartao key={cartao.nome+cartao.numero} onClick={() => handleClickCartao(cartao.numero)}>
                <InfoCartao style={{ flex: 1 }}>{cartao.nome}</InfoCartao>
                <InfoCartao style={{ flex: 0.5 }}>{cartao.numero}</InfoCartao>
                <InfoCartao style={{ flex: 0.5 }}>{cartao.tipo}</InfoCartao>
              </Cartao>
            ))}
          </ListaCartoes>
        )}

        {isSelectedCartao && (
          <>
            <InputGroup>
              <Input
                control={control}
                name="login"
                label="Login"
                disabled
                error={errors.login && errors.login.message}
              />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                name="password"
                label="Senha"
                password
                error={errors.password && errors.password.message}
              />
            </InputGroup>
            <ForgotPassword>
              <ForgotPasswordText onClick={handleClickForgot}>Esqueci a senha</ForgotPasswordText>
            </ForgotPassword>
            <Button onClick={handleSubmit(submitForm)} disabled={loading}>
              {loading ? <Loading /> : "Enviar"}
            </Button>
          </>
        )}
        {isForgotPassword && (    
          
          <>
            <SubTitle text="Digite o email cadastrado para recuperar a senha" icon={FaUser} />
            <InputGroup>
              <Input
                control={control}
                name="email"
                label="Email"
                error={errors.Email && errors.Email.message}
              />
            </InputGroup>
            <Button onClick={handleSubmit(submitForgotPassword)} disabled={loading}>
              {loadingCartao ? <Loading /> : "Enviar"}
            </Button>
          </>
        )}
      </LoginCard>
    </Container>
  );
}

export default Login;
