import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaMapMarkerAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { anos, meses } from "../../utils/datas";
import { getDateFromString } from "../../utils/date";
import { DateInfo } from "../ShareCapital/styles";

import { Container, Filters, InputGroup, Td } from "./styles";

interface MilkSupplyExtractProps {}

function MilkSupplyExtract({}: RouteComponentProps<MilkSupplyExtractProps>) {
  const { user } = useAuth();
  const { control, setValue, handleSubmit } = useForm();
  const [fazendas, setFazendas] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [anomes, setAnomes] = useState(
    `${new Date().getFullYear()}0${new Date().getMonth() + 1}`
  );

  useEffect(() => {
    setLoading(true);
    api
      .get("getForLei", {
        params: {
          CODCLIENTE: user.matricula,
          ANOMES: anomes,
        },
      })
      .then((res: any) => {
        let helper = {};
        res.data.forlei?.forEach((lei) => {
          if (helper[lei.fazenda]) {
            helper[lei.fazenda].push(lei);
          } else {
            helper[lei.fazenda] = [];
            helper[lei.fazenda].push(lei);
          }
        });
        setFazendas(helper);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.matricula, anomes]);

  function submitForm(form) {
    setAnomes(`${form.ano}${form.mes}`);
  }

  return (
    <Container>
      <PageHeader title="Extrato For. Leite"/>

      <Filters>
        <InputGroup>
          <Input control={control} type="select" values={meses} name="mes" placeholder="Mês" />
        </InputGroup>
        <InputGroup>
          <Input control={control} type="select" values={anos} name="ano" placeholder="Ano" />
        </InputGroup>
        <InputGroup>
          <Button onClick={handleSubmit(submitForm)}>CONSULTAR</Button>
        </InputGroup>
      </Filters>
      {loading ? (
        <Loading />
      ) : (
        <>
          {Object.entries(fazendas).map(([key, value]: any) => {
            let total = 0;
            return (
              <>
                <SubTitle text={key} icon={FaMapMarkerAlt} />
                <Card>
                  <DateInfo>Emitido em: 7 de Maio de 2021 às 17:35</DateInfo>
                  <Container className="div-table">
                  <Table>
                    <tr>
                      <th>Data</th>
                      <th>Água</th>
                      <th>Crioscop</th>
                      <th>Quantidade</th>
                      <th>Temperatura</th>
                    </tr>
                    {value.map((info) => {
                      total += info.leite;
                      return (
                        <tr>
                          <td>
                            {getDateFromString(info.datacoleta).toLocaleString(
                              "default",
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td>{info.agua}</td>
                          <td>{info.crioscop}</td>
                          <td>{info.leite}</td>
                          <td>{info.temperatura}</td>
                        </tr>
                      );
                    })}
                    <tr  className="bgBlue">
                      <Td colSpan={11} className="bgBlue">
                        <b>Total: {+total.toFixed(2)}</b>
                      </Td>
                    </tr>
                  </Table>
                  </Container>
                  
                </Card>
              </>
            );
          })}
          {!Object.entries(fazendas).length && <SubTitle text="Nenhum dado encontrado para essa data." icon={FaMapMarkerAlt} />}
        </>
      )}
    </Container>
  );
}

export default MilkSupplyExtract;
