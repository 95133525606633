import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #E5E5E5;
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #000;
  }
  html, body, #root {
    height: 100%;
    font-size: 62.5%;
    overflow-x: hidden;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  p, h1, h2, h3, h4, h5, h6, ul, li, ol,
  *,
  *::after,
  *::before {
    line-height: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  p, h1, h2, h3, h4, h5, h6, ul, li, ol,
  *,
  *:active,
  *:focus {
    outline: none;
    box-sizing: border-box;
  }
  input {
    border-style: solid;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'PT Sans', sans-serif;
  }
`;

export default GlobalStyle;
