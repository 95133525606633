import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import ActiveCards from "../screens/ActiveCard";
import Admin from "../screens/Admin";
import BillsToPay from "../screens/BillsToPay";
import BillsToReceive from "../screens/BillsToReceive";
import ChangePassword from "../screens/ChangePassword";
import ClosingStatement from "../screens/ClosingStatement";
import CreditLimit from "../screens/CreditLimit";
import Home from "../screens/Home";
import MilkSupplyExtract from "../screens/MilkSupplyExtract";
import PurchasesMade from "../screens/PurchasesMade";
import PurchasesStatement from "../screens/PurchasesStatement";
import RegistrationUpdate from "../screens/RegistrationUpdate";
import SelectUser from "../screens/SelectUser";
import ShareCapital from "../screens/ShareCapital";
import Contact from "../screens/Contact";
const loading = <div className="loading"></div>;

export default function RoutesAuth() {
  const { user } = useAuth();

  return (
    <Switch>
      {!!user.firstAccess ? (
        <>
          <Route path="/trocar-senha" component={ChangePassword} />
          <Redirect from="/" to="/trocar-senha" />
        </>
      ) : (
        <>
          <Route path="/home" component={Home} />
          <Route path="/limite-de-credito" component={CreditLimit} />
          <Route path="/capita-social" component={ShareCapital} />
          <Route path="/cartoes-ativos" component={ActiveCards} />
          <Route
            path="/extrato-fornecimento-leite"
            component={MilkSupplyExtract}
          />
          <Route path="/contas-a-pagar" component={BillsToPay} />
          <Route path="/contas-a-receber" component={BillsToReceive} />
          <Route path="/extrato-de-fechamento" component={ClosingStatement} />
          <Route path="/compras-realizadas" component={PurchasesMade} />
          <Route
            path="/demonstrativo-de-compras"
            component={PurchasesStatement}
          />
          <Route
            path="/atualizacao-de-cadastro"
            component={RegistrationUpdate}
          />
          <Route
            path="/canal-de-duvidas"
            component={Contact}
          />
          <Route path="/trocar-senha" component={ChangePassword} />
          <Route path="/admin" component={Admin} />
          <Route path="/usuarios" component={SelectUser} />
          <Redirect from="/" to="/home" />
        </>
      )}
    </Switch>
  );
}
