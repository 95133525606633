import axios from 'axios';

const api = axios.create({
 baseURL: 'https://apiportal.capul.com.br/rest/api/INFOCLIENTES/',
  auth: {
    username: 'APICAPUL',
    password: 'Ap1C4pu1PRD'
  }
});

const apiLogin = axios.create({
baseURL: 'https://portalcapul.capul.com.br/api/'
//baseURL: 'http://localhost:3333/api/'
});



export { api, apiLogin }