import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { RouteComponentProps, useHistory } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import { useAuth } from "../../hooks/auth";
import { api, apiLogin } from "../../services/api";
import { getDateFromString, getStringFromDateString } from "../../utils/date";

import { Container, Form, InputGroup, Row } from "./styles";

function ChangePassword() {
  const { user, cartao, disableFirstAccess, attEmail, signOut } = useAuth();
  const {
    control,
    setError,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState();
  const history = useHistory();

  useEffect(() => {
    setValue("email-atual", user.email);
  })

  async function submitForm(form) {
    if (form.password !== form.confirmPassword || form.email !== form.confirmEmail || (!form.email && !form.password)) {
      if(form.password !== form.confirmPassword) {
        setError("confirmPassword", { message: "As senhas estão diferentes" });
      }
      if(form.email !== form.confirmEmail){
        setError("confirmEmail", { message: "Os emails estão diferentes" });
      }
      if(!form.email && !form.password) {
        setError("email", { message: "Email ou senha obrigatório" });
        setError("password", { message: "Email ou senha obrigatório" });
      }
      return false;
    }

    const dado: any = {
      email: form.email,
    };
    
    console.log("Verificando email")
    var result = await apiLogin.put("emailExists", dado)

    console.log(result)
    if(result.data){
       alert("Email ja cadastrado!")
        return;
      }
    console.log(result)
    setLoading(true);
    try {
      const data: any = {
        numcartao: cartao,
        password: form.password,
      };
      if(form.email){
        data.email = form.email
        var teste = await api.put('putEmail', {cartao, email: form.email})
        console.log("Retorno API: "+teste)
        attEmail(form.email)
      }
      if(form.password){
        await apiLogin.put("changePassword", data);
      }
      disableFirstAccess()
      history.push("/home");
    } catch (err) {
      console.log("ERRO NA ATUALIZACAO DO CADASTRO: "+ err)
      alert("Erro ao atualizar cadastro");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <PageHeader title="Alterar Senha" />

      <SubTitle
        text={`Preencha os campos abaixo para alterar sua senha`}
        icon={FaUser}
      />
      <Card>
        <Form>
          <Row>
          <InputGroup>
              <Input
                control={control}
                name="email-atual"
                label="Email atual"
                disabled
              />
            </InputGroup>
          </Row>
          <Row className="mail">
            <InputGroup>
              <Input
                control={control}
                name="email"
                placeholder="nome@email.com"
                label="Novo Email"
                error={errors.email && errors.email.message}
              />
            </InputGroup>
            <InputGroup >
              <Input
                control={control}
                name="confirmEmail"
                placeholder="nome@email.com"
                label="Confirme o Email"
                error={errors.confirmEmail && errors.confirmEmail.message}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input
                control={control}
                name="password"
                placeholder="Nova Senha"
                password
                label="Nova Senha"
                error={errors.senha && errors.senha.message}
              />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                name="confirmPassword"
                placeholder="Confirme a Senha"
                password
                label="Confirme a Senha"
                error={errors.confirmPassword && errors.confirmPassword.message}
              />
            </InputGroup>
          </Row>
        </Form>
      </Card>
      <Row>
      <Button className="responsive" onClick={handleSubmit(submitForm)} disabled={loading}>
        ATUALIZAR
      </Button>
      
      <Button className="responsive" onClick={() => signOut() && history.push("/")} style={{marginLeft: 15, backgroundColor: '#E10000'}}>
        SAIR
      </Button>
      </Row>
     
    </Container>
  );
}

export default ChangePassword;
