import styled from 'styled-components';
import GadoTopoImage from '../../assets/images/gado-topo.png'
import { devicesMax } from "../../utils/responsive"


export const Container = styled.div`
  width: 100%;
  height: 100px;
  background-color: #007D22;
  background-image: url(${GadoTopoImage});
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconMenu = styled.button`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devicesMax.mobileL}{
    width: 50%;
  }
  
`

export const FlexDiv = styled.div`
  display: flex;
  &.button-title{
    width: 40%;
  }
`

export const Title = styled.h1`
  color: #FFF;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
  
  .titleSmall {
    display: none;
  }

  @media ${devicesMax.tablet} {
    font-size: 2.5rem;
  }

  @media ${ devicesMax.mobileL || devicesMax.mobileS|| devicesMax.mobileM} {

    font-size: 1.8rem;
    
    @media ${devicesMax.mobileM} {
      font-size: 1.5rem;
    }

    @media ${devicesMax.mobileS} {
      font-size: 1.3rem;
    }

    .titleSmall {
      display: block;
    }
    .titleFull {
      display: none;
    }
  }
  
`


export const UserInfo = styled.div`
  display: flex;

    &.div-info{
      display:block;
    }
  }
`

export const UserText = styled.div`
  justify-self: flex-end;
  font-size: 2.0rem;
  color: #FFF;
  font-weight: 700;
  margin-right: 30px;

  @media ${devicesMax.tablet} {
    font-size: 1.5rem;
    margin-right: 0px;
  }

  @media ${ devicesMax.mobileL} {
    font-size: 1.4rem;
  }

  @media ${ devicesMax.mobileM} {
    font-size: 1.2rem;
  }

  @media ${ devicesMax.mobileS} {
    font-size: 1.1rem;
  }
`

export const UserMore = styled.button`
  padding-right: 30px;
`