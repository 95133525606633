import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { getDateFromString, getStringFromDateString } from "../../utils/date";
import { DateInfo } from "../ShareCapital/styles";

import { Container, Filters, InputGroup, Td } from "./styles";

interface BillsToReceiveProps {}

function BillsToReceive({}: RouteComponentProps<BillsToReceiveProps>) {
  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [contasreceber, setContasreceber] = useState([]);
  const [vencde, setVencde] = useState("");
  const [vencate, setVencate] = useState("");

  useEffect(() => {
    if (vencde && vencate) {
      setLoading(true);
      api
        .get("getCReceber", {
          params: {
            CODCLIENTE: user.matricula,
            VENCDE: vencde,
            VENCATE: vencate,
          },
        })
        .then((res: any) => {
          setContasreceber(res.data.contasreceber);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user.matricula, vencde, vencate]);

  function submitForm(form) {
    const vencde = getStringFromDateString(form.vencde);
    const vencate = getStringFromDateString(form.vencate);
    setVencde(vencde);
    setVencate(vencate);
  }

  return (
    <Container>
      <PageHeader title="Contas a Receber" />

      <SubTitle text="Informe o período:" icon={FaCalendarAlt} />
      <Filters>
        <InputGroup>
          <Input
            control={control}
            mask="99/99/9999"
            name="vencde"
            placeholder="Data Inicial"
          />
        </InputGroup>
        <InputGroup>
          <Input
            control={control}
            mask="99/99/9999"
            name="vencate"
            placeholder="Data Final"
          />
        </InputGroup>
        <InputGroup>
          <Button onClick={handleSubmit(submitForm)}>CONSULTAR</Button>
        </InputGroup>
      </Filters>

      {loading ? (
        <Loading />
      ) : contasreceber.length ? (
        <Card>
          <>
            <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>

            <SubTitle
              text={`Quantidade de Registros listados: ${contasreceber.length}`}
              sub
            />
            <Container className="div-table">
            <Table>
              <tr>
                <th>Filial</th>
                <th>Loja</th>
                <th>Emissão</th>
                <th>Número</th>
                <th>Parc/Qtd</th>
                <th>Saldo</th>
                <th>Tipo</th>
                <th>Vencimento</th>
                <th>Valor Título</th>
                <th>Extrato de Fechamento</th>
              </tr>
              {contasreceber.map((contareceber) => (
                <tr>
                  <td>{contareceber.filial}</td>
                  <td>{contareceber.loja}</td>
                  <td>
                    {getDateFromString(contareceber.emissao).toLocaleString(
                      "default",
                      { year: "numeric", month: "numeric", day: "numeric" }
                    )}
                  </td>
                  <td>{contareceber.numero}</td>
                  <td>{contareceber.parcela}</td>
                  <td>{contareceber.saldo}</td>
                  <td>{contareceber.tipo}</td>
                  <td>
                    {getDateFromString(contareceber.vencimento).toLocaleString(
                      "default",
                      { year: "numeric", month: "numeric", day: "numeric" }
                    )}
                  </td>
                  <td>{contareceber.valor}</td>
                  <td>{contareceber.fechamento}</td>
                </tr>
              ))}
              <tr>
                <Td
                  colSpan={11}
                  className="bgBlue"
                >
                  Soma total:{" "}
                  <b>
                    R${" "}
                    {
                      +contasreceber
                        .map((conta) => conta.valor)
                        .reduce((soma, i) => soma + i, 0)
                        .toFixed(2)
                    }
                  </b>
                </Td>
              </tr>
            </Table>
            </Container>
            
          </>
        </Card>
      ) : (
        <SubTitle
          text="Nenhum dado encontrado para essa data."
          icon={FaMapMarkerAlt}
        />
      )}
    </Container>
  );
}

export default BillsToReceive;
