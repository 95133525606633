import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"
export const Container = styled.div`
  min-height: 1000px;
`;

export const Form = styled.form``

export const Row = styled.div`
  display: flex;


  @media ${devicesMax.mobileL}{
    
    &.mail{
      display: block;
    }
    
  }
`

export const InputGroup = styled.div`
  flex: 1;
  margin: 15px 15px 15px 0;

  

`;