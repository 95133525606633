import React, { ReactNode, useEffect, useState } from "react";
import {
  FaHome,
  FaCreditCard,
  FaMoneyBill,
  FaChartBar,
  FaMoneyCheckAlt,
  FaFileInvoice,
  FaNewspaper,
  FaList,
  FaListAlt,
  FaFileAlt,
  FaUser,
  FaBell
} from "react-icons/fa";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import ItemMenu from "./ItemMenu";

import { Container, Separator } from "./styles";
import { sizesNumber,useWindowDimensions } from "../../utils/responsive"
import { P } from "../../screens/Home/styles";
import Modal from "../Modal";

interface SidebarProps { 

}

const MENU = [
  {
    descricao: "LIMITE DE CREDITO",
    label: "Limite de Crédito",
    route: "limite-de-credito",
    codigo: "001",
    key: 1,
    icon: FaCreditCard,
  },
  {
    descricao: "CAPITAL SOCIAL",
    label: "Capital Social",
    route: "capita-social",
    codigo: "002",
    key: 2,
    icon: FaMoneyBill,
  },
  {
    descricao: "CARTOES ATIVOS",
    label: "Cartões Ativos",
    route: "cartoes-ativos",
    codigo: "003",
    key: 3,
    icon: FaCreditCard,
  },
  {
    descricao: "EXTRATO FORNECIMENTO DE LEITE",
    label: "Extrato For. Leite",
    route: "extrato-fornecimento-leite",
    codigo: "004",
    key: 4,
    icon: FaChartBar,
  },
  {
    descricao: "CONTAS A PAGAR DO CLIENTE",
    label: "Contas a Pagar",
    route: "contas-a-pagar",
    codigo: "005",
    key: 5,
    icon: FaMoneyCheckAlt,
  },
  {
    descricao: "CONTAS A RECEBER DO CLIENTE",
    label: "Contas a Receber",
    route: "contas-a-receber",
    codigo: "006",
    key: 6,
    icon: FaFileInvoice,
  },
  {
    descricao: "COMPRAS REALIZADAS",
    label: "Extrato de Fechamento",
    route: "extrato-de-fechamento",
    codigo: "007",
    key: 7,
    icon: FaNewspaper,
  },
  {
    descricao: "DEMONSTRATIVO DE COMPRAS",
    label: "Compras Realizadas",
    route: "compras-realizadas",
    codigo: "008",
    key: 8,
    icon: FaList,
  },
  {
    descricao: "EXTRATO DE FECHAMENTO",
    label: "Demonstrativo de Compras",
    route: "demonstrativo-de-compras",
    codigo: "009",
    key: 9,
    icon: FaListAlt,
  },
  {
    descricao: "ATUALIZACAO DE CADASTRO",
    label: "Atualização de Cadastro",
    route: "atualizacao-de-cadastro",
    codigo: "010",
    key: 10,
    icon: FaFileAlt,
  },


];

function Sidebar({ }: SidebarProps) {
  const { isOpenMenu, signOut, user, cartao, toggleMenu } = useAuth();
  const [avisoOpen, setAvisoOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFuncionario, setIsFuncionario] = useState(false);
  const { width } = useWindowDimensions(); //dimens�o do viewport

  useEffect(() => {
    getCartoes()
    checkFuncionario()
    setAvisoOpen(false)
  }, [cartao])

  async function checkFuncionario() {
   if(user.matricula.startsWith("E0")){
     setIsFuncionario(true);
    }
  }

  async function getCartoes() {
    const res = await api.get<any>("getCartoesAtivos", {
      params: {
        CODCLIENTE: user.matricula,
      },
    })
    const card = res.data?.cartoesativos.find(card => card.numero?.trim() === cartao?.trim())
    if (card?.tipo === "ADMIN") {
      setIsAdmin(true)
    }
  }

 

  function validaToggleMenu(){
    return isOpenMenu && (width <= sizesNumber.mobileL);
  }

  const userPermissoes = user.permissoes?.filter(item => item.permissao === "S")
  let menuFiltered = MENU.filter((menuItem) =>
    userPermissoes?.some((item) => item.codigo === menuItem.codigo)
  );
  menuFiltered = user.titular === 'S' ? MENU : menuFiltered

  return (
   

    <Container isOpenMenu={isOpenMenu}>
      <ItemMenu icon={FaHome} label="Página Inicial" route="home" onClick={() => { if(validaToggleMenu()) toggleMenu()} }/>
      <Separator />
      {menuFiltered.map((item) => (
        <ItemMenu key={item.key} icon={item.icon} label={item.label} route={item.route} onClick={() => { if(validaToggleMenu()) toggleMenu()}}/>
      ))}
       {isFuncionario && <ItemMenu icon={FaBell} label="Canal de Denúncias" route="/canal-de-duvidas" onClick={() => setAvisoOpen(true)}  />}
      <Separator />
      <ItemMenu icon={FaFileAlt} label="Trocar Senha" route="trocar-senha" onClick={() => { if(validaToggleMenu()) toggleMenu()}} />
      {isAdmin && <ItemMenu icon={FaUser} label="Admin" route="admin" />}
     
      <ItemMenu icon={FaUser} label="Sair" onClick={signOut} />
    </Container>
    
  );
  
    
}

export default Sidebar;
