import { Container, Content, Separator, Title } from './styles';

interface NoticeProps {
  date: Date;
  title: string;
  content: string;
}

function Notice({ date, title, content }: NoticeProps) {
  return (
    <Container>
      <Title>
        {date.toLocaleDateString()} - {title}
      </Title>
      <Content>
        {content}
      </Content>
      <Separator />
    </Container>
  );
};

export default Notice;
