import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { DateInfo } from "../ShareCapital/styles";

import { Container } from "./styles";

function SelectUser() {
  const { acessos, loading, changeUser } = useAuth();

  function handleSelectUser(associado) {
    console.log(associado)
    changeUser(associado)
  }

  return (
    <Container>
      <PageHeader title="Listagem de Usuários" />

      <SubTitle
        text={`Escolha um usuário para inicias suas consultas:`}
        icon={FaUser}
      />
      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table>
              <tr>
                <th>Nome</th>
                <th>Código</th>
              </tr>
              {acessos.acessos[0].associado.map((assoc) => (
                <tr
                  onClick={() => handleSelectUser(assoc)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{assoc.nome || "Nome"}</td>
                  <td>{assoc.matricula}</td>
                </tr>
              ))}
            </Table>
          </>
        )}
      </Card>
    </Container>
  );
}

export default SelectUser;
