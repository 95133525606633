import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaBell } from "react-icons/fa";
import { RouteComponentProps, useHistory } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import { useAuth } from "../../hooks/auth";
import { apiLogin } from "../../services/api";
import { getDateFromString, getStringFromDateString } from "../../utils/date";
import queryString from 'query-string';
import { Container, Form, InputGroup, Row } from "./styles";
import { P } from "../Home/styles";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";

interface ContactProps {}
interface FormData {
  mensagem: string;
  assunto: string;
}
function Contact({}: RouteComponentProps<ContactProps>) {
  const { user } = useAuth();
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(true);
  const [combos, setCombos] = useState<any>();
  const history = useHistory();
  const location = useLocation();


  const submitForgotPassword = async (form: any) => {
    console.log(form);
    
    const params = queryString.parse(location.search)
    try {
      setLoading(true);
       await apiLogin.post("/contact", { assunto: form.assunto, mensagem: form.mensagem, matricula: form.matricula, setor: form.setor});
      alert("Email enviado");
      setLoading(true);
      history.push("home");
    } catch (error) {
      alert("Algo deu errado. O email correto?");
    } finally {
      setLoading(false);
    }
  };

    return (
      <>
      <Modal 
       text={"ESTOU CIENTE QUE A DENUNCIAÇÃO CALUNIOSA É CRIME NA FORMA DO ARTIGO 339 DO CÓDIGO PENAL."}
       buttonText={"Ciente"} 
       widthProp={233}
       heightProp={300}
       
      /> 
      <Container>
        <PageHeader title="Canal de Denúncias" />
  
        <SubTitle
          text={`Entre em contato com a Capul`}
          icon={FaBell}
        />
        <Card>
        {loading ? (<Loading />) : (
              
              <>
              <P>
              Envie-nos um e-mail. Preencha o formulário abaixo descrevendo o assunto da denúncia. Será analisado pela comissão e responderemos o mais breve possível.
        </P>
        <Form>
        <Row>
            <InputGroup>
              <Input control={control} name="matricula" label="Matrícula" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="setor" label="Departamento/setor" />
            </InputGroup>
          </Row>
  
        <Row>
            <InputGroup>
              <Input control={control} name="assunto" label="Assunto" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} type="text" name="mensagem" label="Mensagem" />
            </InputGroup>
          </Row>
        </Form>
              
              </>
          )
          }
        </Card>

        <Button onClick={handleSubmit(submitForgotPassword)}>Enviar</Button>
      </Container> 
      </>
            
    );
}

export default Contact;
