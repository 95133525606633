import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { RouteComponentProps, useHistory } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import { useAuth } from "../../hooks/auth";
import { api,apiLogin } from "../../services/api";
import { getDateFromString, getStringFromDateString } from "../../utils/date";

import { Container, Form, InputGroup, Row } from "./styles";

interface RegistrationUpdateProps {}

function RegistrationUpdate({}: RouteComponentProps<RegistrationUpdateProps>) {
  const { user } = useAuth();
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [combos, setCombos] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    api
      .get<any>("getLimite", { params: { CODCLIENTE: user.matricula } })
      .then((res) => {
        if (res.data.manutencaocompartilhada) {
          // Object.entries(res.data.manutencaocompartilhada).forEach(
          //   ([key, value]: any) => {
          //     setValue(key, value);
          //   }
          // );
          const dtnasc = getDateFromString(
            res.data.manutencaocompartilhada.dtnasc
          );
          setValue("nome", res.data.nome.trim());
          setValue("ddd", res.data.manutencaocompartilhada.ddd.trim());
          setValue("xsexo", res.data.manutencaocompartilhada.xsexo.trim());
          setValue("contato", res.data.manutencaocompartilhada.contato.trim());
          setValue("email", res.data.manutencaocompartilhada.email.trim());
          setValue("xnmae", res.data.manutencaocompartilhada.xnmae.trim());
          setValue("xnpai", res.data.manutencaocompartilhada.xnpai.trim());
          setValue("xconjug", res.data.manutencaocompartilhada.xconjug.trim());
          setValue("xnacion", res.data.manutencaocompartilhada.xnacion.trim());
          setValue("xnatura", res.data.manutencaocompartilhada.xnatura.trim());
          setValue("endcob", res.data.manutencaocompartilhada.endcob.trim());
          setValue("bairroc", res.data.manutencaocompartilhada.bairroc.trim());
          setValue("cepc", res.data.manutencaocompartilhada.cepc.trim());
          setValue("estc", res.data.manutencaocompartilhada.estc.trim());
          setValue("munc", res.data.manutencaocompartilhada.munc.trim());
          setValue(
            "dtnasc",
            dtnasc.toLocaleString("default", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          );
        }
        api.get('getCamposCombo').then(res => {
          setCombos(res.data)
        })
      });
  }, []);

  async function submitForm(form) {
    
    setLoading(true);
    try {
      const data = {
        matricula: user.matricula,
        manutencaocompartilhada: {
          ...form,
          dtnasc: getStringFromDateString(form.dtnasc),
        },
      };

      const dado: any = {
        email: form.email,
      };
      
      var result = await apiLogin.put("emailExists", dado)
  
      if(result.data){
         alert("Email ja cadastrado!")
          return;
        }
  
      await api.put("putCliente", data);
      alert(
        "Solicitação para alteração realizada com sucesso, aguardando análise."
      );
      history.push("/home");
    } catch (err) {
      console.log("ERRO NA ATUALIZACAO DO CADASTRO: "+ err)
      alert("Erro ao atualizar cadastro");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <PageHeader title="Alteração Cadastral" />

      <SubTitle
        text={`Atualize seus dados cadastrais`}
        icon={FaUser}
      />
      <Card>
        <Form>
          <Row>
            <InputGroup>
              <Input
                control={control}
                name="nome"
                placeholder="Nome"
                label="Nome"
              />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                type="select"
                values={combos?.xsexo.map(item => ({value: item.codigo, label: item.descricao}))}
                name="xsexo"
                placeholder="Selecione"
                label="Sexo"
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input
                control={control}
                type="select"
                values={combos?.xcivil.map(item => ({value: item.codigo, label: item.descricao}))}
                name="xcivil"
                placeholder="Selecione"
                label="Estado Civil"
              />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                type="select"
                values={combos?.xregcas.map(item => ({value: item.codigo, label: item.descricao}))}
                name="xregcas"
                placeholder="Selecione"
                label="Regime de Casamento"
              />
            </InputGroup>
            {/* <InputGroup>
              <Input
                control={control}
                name="abertura"
                mask="99/99/9999"
                label="Data de abertura"
              />
            </InputGroup> */}
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="ddd" mask="99" label="DDD" />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="contato" label="Celular" />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="tel" label="Telefone" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input
                control={control}
                name="dtnasc"
                label="Data de nascimento"
              />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="email" label="Email" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="xnmae" label="Nome da Mãe" />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="xnpai" label="Nome do Pai" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="xconjug" label="Nome do Cônjuge" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="xnacion" label="Nacionalidade" />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="xnatura" label="Naturalidade:" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input
                control={control}
                name="endcob"
                label="Endereço de Cobrança"
              />
            </InputGroup>
            {/* <InputGroup>
              <Input
                control={control}
                name="pai"
                label="Nome de Contato"
              />
            </InputGroup> */}
          </Row>
          <Row>
            <InputGroup>
              <Input
                control={control}
                name="bairroc"
                label="Bairro de Cobrança"
              />
            </InputGroup>
            <InputGroup>
              <Input control={control} name="cepc" label="CEP de Cobrança" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input control={control} name="estc" label="UF de Cobrança" />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                name="munc"
                label="Município de Cobrança"
              />
            </InputGroup>
          </Row>
          {/* <Row>
            <InputGroup>
              <Input
                control={control}
                name="mae"
                type="select"
                label="Categoria da Fazenda"
              />
            </InputGroup>
            <InputGroup>
              <Input
                control={control}
                name="pai"
                type="select"
                label="Atividade da Fazenda:"
              />
            </InputGroup>
          </Row> */}
        </Form>
      </Card>
      <Button onClick={handleSubmit(submitForm)}>ATUALIZAR</Button>
    </Container>
  );
}

export default RegistrationUpdate;
