import styled from 'styled-components';
import { devicesMax } from "../../../utils/responsive"

export const Container = styled.button`
  background: #007433;
  border-radius: 5px;
  padding: 1.6rem 4.0rem;  
  border: none;
  height: 5.0rem;

  @media ${devicesMax.mobileL}{
    
    padding: 1.2rem 3.0rem;
    height: 4.0rem;
  }
`;

export const Text = styled.span`
  font-size: 1.6rem;
  color: #FFF;
  line-height: 2.1rem;

  @media ${devicesMax.mobileL}{
    
    font-size: 1.23rem;
    line-height: 1.6rem;
  }
`;