import React from 'react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';

import { Container, IconContainer, Text } from './styles';

interface ItemMenuProps {
  label: string;
  route?: string;
  icon: IconType;
  onClick?: () => void;
}

function ItemMenu({ icon: Icon, label, route = '', ...rest }: ItemMenuProps) {
  const { isOpenMenu } = useAuth();
  const location = useLocation();
  
  return (
    <Container to={route} {...rest}>
      <IconContainer>
        <Icon color={!!(location.pathname.includes(route) && route) ? "#FFF" : "#B4B4B4"} size={20} />
      </IconContainer>
      <Text isOpenMenu={isOpenMenu} active={!!(location.pathname.includes(route) && route)}>{label}</Text>
    </Container>
  );
};

export default ItemMenu;
