import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { apiLogin } from "../../services/api";

import { Container, InputGroup, Row } from "./styles";

interface AdminProps {}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Admin({}: RouteComponentProps<AdminProps>) {
  const { cartao } = useAuth();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, handleSubmit, setValue, reset } = useForm();
  const [selectedPost, setSelectedPost] = useState<any>({});

  async function fetchPosts() {
    setLoading(true);
    const res = await apiLogin.get<any>("post");
    setPosts(res.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  function handleCreatePost() {
    setIsModalOpen(true);
    setSelectedPost({});
    reset();
  }

  function handleEditPost(post) {
    setIsModalOpen(true);
    setSelectedPost(post);
    setValue("title", post.title);
    setValue("content", post.content);
  }

  async function handleSavePost(form) {
    const data = { ...form, numcartao: cartao };
    try {
      if (selectedPost.id) {
        await apiLogin.put(`post/${selectedPost.id}`, data);
      } else {
        await apiLogin.post("post", data);
      }
    } catch (err) {
      alert("Erro ao salvar post");
    } finally {
      fetchPosts();
      reset();
      setIsModalOpen(false);
    }
  }

  async function handleDeletePost(post) {
    const dialog = window.confirm("Deseja excluir o post?");
    if (dialog) {
      try {
        if (post.id) {
          await apiLogin.delete(`post/${post.id}`);
        }
      } catch (err) {
        alert("Erro ao deletar post");
      } finally {
        fetchPosts();
      }
    }
  }

  return (
    <Container>
      <PageHeader title="Listagem de Notificações" />

      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table>
              <tr>
                <th>Título</th>
                <th></th>
                <th></th>
              </tr>
              {posts.map((post) => (
                <tr>
                  <td>{post.title}</td>
                  <td
                    onClick={() => handleEditPost(post)}
                    style={{ cursor: "pointer" }}
                  >
                    Editar
                  </td>
                  <td
                    onClick={() => handleDeletePost(post)}
                    style={{ cursor: "pointer" }}
                  >
                    Excluir
                  </td>
                </tr>
              ))}
            </Table>
          </>
        )}
      </Card>
      <Button onClick={handleCreatePost}>Criar Notificação</Button>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Itens da compra"
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
      >
        <PageHeader title="Novo post" hasPath={false} />
        <Row style={{ minWidth: "500px" }}>
          <InputGroup>
            <Input control={control} name="title" label="Titulo" />
          </InputGroup>
        </Row>
        <Row>
          <InputGroup>
            <Input
              control={control}
              type="text"
              rows={5}
              name="content"
              label="Conteudo"
            />
          </InputGroup>
        </Row>
        <Button onClick={handleSubmit(handleSavePost)}>Salvar</Button>
      </Modal>
    </Container>
  );
}

export default Admin;
