import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"
export const Container = styled.div`

`;

export const Filters = styled.div`
  display: flex;

  @media ${devicesMax.mobileL}{
    display: block;
  }
` 

export const InputGroup = styled.div`
  margin: 15px 15px 15px 0;
`;