import styled from "styled-components";
import { devicesMax} from "../../..//utils/responsive"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: #1f2f46;

  margin-bottom: 20px;
`;

export const Content = styled.div`
  font-size: 1.6rem;
  line-height: 2.1rem;

  color: #505050;
`;


export const Separator = styled.div`
  background-color: #D0D0D0;
  width: 100%;
  height: 2px;
  background: rgb(208,208,208);
  background: linear-gradient(90deg, rgba(208,208,208,1) 0%, rgba(208,208,208,0) 100%);
  margin: 20px 0px;

  @media ${devicesMax.tablet} {
    background-color: #D0D0D0;
  }
`;