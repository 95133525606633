import styled from 'styled-components';

export const Container = styled.div`
  min-height: 1000px;
`;

export const Row = styled.div`
  display: flex;
`

export const InputGroup = styled.div`
  flex: 1;
  margin: 15px 15px 15px 0;
`;