import styled from "styled-components";
import { devicesMax } from "../../utils/responsive"




interface TextProps {
  sub: boolean;
}



export const Container = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const Text = styled.p<TextProps>`
  color: #1f2f46;
  font-weight: 700;
  font-size: ${({sub}) => sub ? '1.8rem' : '2.0rem'};

  margin-left: ${({sub}) => sub ? '0' : '1.8rem'};

  @media ${devicesMax.tablet} {
    font-size: ${({sub}) => sub ? '1.8rem' : '2.0rem'};
  }

  @media ${devicesMax.mobileL} {
    font-size: ${({sub}) => sub ? '1.5rem' : '1.7rem'};
  }
`;
