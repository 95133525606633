import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import TheLayout from "../components/TheLayout";
import { useAuth } from "../hooks/auth";
import ForgotPassword from "../screens/ForgotPassword";
import Login from "../screens/Login";

const loading = <div className="loading"></div>;

export default function Routes() {
  const { user } = useAuth();
  const signed = user.matricula ? true : false;

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {signed ? (
            <>
              <Route path="/" render={(props) => <TheLayout {...props} />} />
            </>
          ) : (
            <>
              {/*<Route
                exact
                path="/"
                render={(props) => <Login {...props} />}
          />*/}
              <Route
                path="/recuperar-senha"
                exact
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route render={(props) => <Login {...props} />} />
            </>
          )}
          
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}
