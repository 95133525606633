import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { devicesMax } from "../../../utils/responsive"

interface TextProps {
  isOpenMenu: boolean;
  active: boolean;
}

export const Text = styled.p<TextProps>`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({active}) => active ? '#FFF' : '#B4B4B4'};
  white-space: nowrap;
  width: ${({isOpenMenu}) => isOpenMenu ? '100%' : '0'};
  transition: all 0.5s ease;
  margin-left: ${({isOpenMenu}) => isOpenMenu ? '27px' : '0'};
  overflow: hidden;
`

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  background: none;
  border: none;
  text-align: left;
  &:hover ${Text}, &:hover svg {
    color: #FFF !important;
    fill: #FFF;
    transition: all 0.1s ease;

  }
`;


export const IconContainer = styled.div`
`;