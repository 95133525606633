import { ReactNode } from 'react';

import { Container } from './styles';
import { P } from '../../screens/Home/styles';
import Button from "../Form/Button";

interface ModalProps {
  buttonText: String
  text: String
  widthProp: Number
  heightProp: Number
  clickFunction ?
}


function closeModal(){
  let body =  document.getElementsByTagName("body").item(0);
   body.style.setProperty('background-color','#E5E5E5', 'important');
   body.style.setProperty('pointer-events','visible', 'important');

  let teste  = document.getElementsByTagName("div");
  for(let i=0; i < teste.length; i++){
    let div = teste.item(i);

    if(div.getAttribute("id") == 'modal'){
      div.style.setProperty('display', 'none');
    } 
  }
}

function Modal({ text, buttonText, clickFunction,widthProp,heightProp }: ModalProps) {

 

  return (
    <>
    <div >
      <Container id='modal' style={{backgroundColor: '#1F2F46', height: '20%', alignItems: 'center'}}>
        
      <P color={'white'}>
          {text}
      </P>
      <Button onClick={() => closeModal()}>
          {buttonText}
      </Button>
      </Container>
      
    </div>
     
    </>
  );
};

export default Modal;
