import React from "react";
import { useAuth } from "../../hooks/auth";
import Content from "../Content";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import { Container, Wrapper, Body } from "./styles";

interface TheLayoutProps {}

function TheLayout({}: TheLayoutProps) {
  const { user } = useAuth();

  return (
    <Container>
      <Header />
      <Wrapper>
        <Body>
          {!user.firstAccess && <Sidebar />}
          <Content />
        </Body>
      </Wrapper>
      {/* <Footer /> */}
    </Container>
  );
}

export default TheLayout;
