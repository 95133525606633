import React from 'react';
import RoutesAuth from '../../routes/auth';

import { Container } from './styles';


function Content() {
  return (
    <Container>
      <RoutesAuth />
    </Container>
  );
};

export default Content;
