import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 5px;

  padding: 20px;

  margin-bottom: 30px;

  @media ${devicesMax.mobileL}{
    max-width: 330px;
    margin-right: 10px;
  }

  @media ${devicesMax.mobileM}{
    max-width: 300px;
    margin-right: 8px;
  }

  @media ${devicesMax.mobileS}{
    max-width: 250px;
    margin-right: 5px;
  }
`;
