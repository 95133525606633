import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalculator, FaStore } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { getDateFromString, getMonthCapilized } from "../../utils/date";

import { Container, Filters, InputGroup } from "./styles";

interface CreditLimitProps {}

function CreditLimit(props: RouteComponentProps<CreditLimitProps>) {
  const { user } = useAuth();
  const [limit, setLimit] = useState<any>({});
  const [activeRegisters, setActiveRegisters] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { control, setValue } = useForm();

  function toCurrency(number: number) {
    return number?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  useEffect(() => {
    setLoading(true);
    api
      .get("getLimite", {
        params: { CODCLIENTE: user.matricula },
      })
      .then((res: any) => {
        setLimit(res.data.limite);
        setActiveRegisters(res.data.cadastrosativos);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.matricula]);

  useEffect(() => {
    setValue("desctipolimite", limit.desctipolimite);
    setValue("limitegeral", toCurrency(limit.limitegeral));
    setValue("limitemensal", toCurrency(limit.limitemensal));
    setValue("titulosemaberto", toCurrency(limit.titulosemaberto));
    setValue("limitegeraldisponivel", toCurrency(limit.limitegeraldisponivel));
  }, [limit]);

  return (
    <Container>
      <PageHeader title="Limite de Crédito" />

      <SubTitle text="Limite de Crédito" icon={FaCalculator} />
      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Filters className="semCelular">
              <InputGroup>
                <Input
                  control={control}
                  name="desctipolimite"
                  label="Tipo de Limite"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
              <InputGroup>
                <Input
                  control={control}
                  name="limitegeral"
                  label="Limite Geral"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
              <InputGroup>
                <Input
                  control={control}
                  name="limitemensal"
                  label="Limite Mensal"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>

              <InputGroup>
                <Input
                  control={control}
                  name="titulosemaberto"
                  label="Títulos em Aberto"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
              <InputGroup >
                <Input
                  control={control}
                  name="limitegeraldisponivel"
                  label="Limite Disponível"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
            </Filters>
          
            <Filters className="comCelular">
                    <InputGroup>
                      <Input
                        control={control}
                        name="desctipolimite"
                        label="Tipo de Limite"
                        placeholder="R$ 20.000,00"
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input
                        control={control}
                        name="limitegeral"
                        label="Limite Geral"
                        placeholder="R$ 20.000,00"
                        disabled
                      />
                    </InputGroup>

                    <InputGroup>
                <Input
                  control={control}
                  name="limitemensal"
                  label="Limite Mensal"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>

              <InputGroup>
                <Input
                  control={control}
                  name="titulosemaberto"
                  label="Títulos em Aberto"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
              <InputGroup >
                <Input
                  control={control}
                  name="limitegeraldisponivel"
                  label="Limite Disponível"
                  placeholder="R$ 20.000,00"
                  disabled
                />
              </InputGroup>
                

               
                

            </Filters>
            <SubTitle text="Projeção de Limite de Crédito" sub />
            <Container className="div-table">
            <Table>
              <tr>
                <th>Mês</th>
                <th>Ano</th>
                <th>Títulos</th>
                <th>Limite Mensal</th>
                <th>Limite Disponível</th>
              </tr>
              {limit.dividasmensais?.map((divida) => (
                <tr>
                  <td>{getMonthCapilized(getDateFromString(divida.anomes))}</td>
                  <td>{getDateFromString(divida.anomes).getFullYear()}</td>
                  <td>
                    {divida.valor.toLocaleString("default", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    {limit.limitemensal.toLocaleString("default", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    <b>
                      {divida.limitedisponivel.toLocaleString("default", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </b>
                  </td>
                </tr>
              ))}
            </Table>
            </Container>
            
          </>
        )}
      </Card>

      <SubTitle text="Cadastros Ativos" icon={FaStore} />
      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
          <Container className="div-table">
          <Table>
              <tr>
                <th>Loja</th>
                <th>Estado</th>
                <th>Município</th>
                <th>CPF/CNPJ</th>
                <th>IE</th>
                <th>Endereço</th>
              </tr>
              {activeRegisters.map((register) => (
                <tr>
                  <td>{register.loja}</td>
                  <td>{register.estado}</td>
                  <td>{register.municipio}</td>
                  <td>{register.cgc}</td>
                  <td>{register.ie}</td>
                  <td>{register.endereco}</td>
                </tr>
              ))}
            </Table>
          </Container>
           
          </>
        )}
      </Card>
    </Container>
  );
}

export default CreditLimit;
