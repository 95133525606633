import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"
import { devicesMin } from "../../utils/responsive"

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  flex: 3;
  margin-right: 15px;
`

export const Side = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devicesMax.tablet || devicesMax.mobileL || devicesMax.mobileS|| devicesMax.mobileM} {
    display: none;
  }
`

export const P = styled.p`
  font-size: 2rem;
  line-height: 2.6rem !important;
  
  color: ${(props) => props.color};
  
  @media ${devicesMax.laptop} {
    font-size: 1.7rem;
    line-height: 2.2rem !important;
  }

  @media ${devicesMax.laptop && devicesMin.tablet} {
    font-size: 1.6rem;
    line-height: 1.9rem !important;
  }

  @media ${devicesMax.tablet} {
    font-size: 1.4rem;
    line-height: 1.8rem !important;
  }


  @media ${ devicesMax.mobileL} {
    font-size: 1.2rem;
    line-height: 1.6rem !important;
  }

 
`

export const CapulImg = styled.img`
  margin-bottom: 17%;
  

  @media ${devicesMax.tablet} {
    display: none;
  }
`

export const RegistrationImg = styled.img`

@media ${devicesMax.tablet} {
  display: none;
}
`

