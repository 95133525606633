import { FaHome } from "react-icons/fa";
import { Container, Path, PathText, Title } from "./styles";

interface PageHeaderProps {
  title: string;
  hasPath?: boolean;
}

function PageHeader({ title, hasPath = true }: PageHeaderProps) {
  return (
    <Container>
      <Title>{title}</Title>
      {hasPath && (
        <Path>
          <FaHome color="#b4b4b4" size={20}/>
          <PathText>Página Inicial / {title}</PathText>
        </Path>
      )}
    </Container>
  );
}

export default PageHeader;
