import styled from "styled-components";
import { devicesMax } from "../../utils/responsive"

export const Container = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 2.6rem;
  color: #007D22;

  @media ${devicesMax.tablet} {
    font-size: 2.2rem;
  }

  @media ${devicesMax.mobileL} {
    font-size: 1.9rem;
  }
`;

export const Path = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  
`;

export const PathText = styled.div`
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.3rem;

  color: #b4b4b4;

  margin-left: 10px;

  @media ${devicesMax.mobileL} {
    &.block{
      display:block;
    }
  }
`;
