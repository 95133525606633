import styled from 'styled-components';
import {devicesMax} from "../..//utils/responsive"

export const Container = styled.div`
@media ${devicesMax.tablet}{
  &.div-table{
    overflow-x:auto;
  }
}

}
`;

export const Filters = styled.div`
  display: flex;

 &.comCelular{
  display: none;
 }

  @media ${devicesMax.tablet} { 

    &.comCelular {
      display:block;
     }

     &.semCelular {
      display:none;
     }
  }

 
`

export const InputGroup = styled.div`
  margin: 15px;

  @media ${devicesMax.mobileL} {
    display:block;
  }


`;