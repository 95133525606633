import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt, FaUser } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { b64toBlob } from "../../utils/base62";
import { anos, meses } from "../../utils/datas";
import { Filters, InputGroup } from "../BillsToPay/styles";

import { Container } from "./styles";

interface PurchasesStatementProps {}

function PurchasesStatement({}: RouteComponentProps<PurchasesStatementProps>) {
  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  async function getPdf(mesde: string, mesate: string, ano: string) {
    setLoading(true);
    setNoData(false);
    let newTab = window.open();
    newTab.document.write("Carregando...");
    newTab.document.title = "Carregando...";
    try {
      const res = await api.get<any>("getDemoCompras", {
        params: {
          CODCLIENTE: user.matricula,
          ANO: ano,
          MESDE: mesde,
          MESATE: mesate,
        },
      });
      if (!res.data.pdf) throw new Error("Erro ao requisitar pdf");
      const blob = b64toBlob(res.data.pdf, "application/pdf");
      const url = URL.createObjectURL(blob);
      newTab.location.href = url;
    } catch (err) {
      newTab.close();
      setNoData(true);
    } finally {
      setLoading(false);
    }
  }

  function submitForm(form) {
    if (form.mesinicial && form.mesfinal && form.ano) {
      getPdf(form.mesinicial, form.mesfinal, form.ano);
    }
  }

  return (
    <Container>
      <PageHeader title="Demonstrativo de Compras" />

      <SubTitle text="Informe o período:" icon={FaCalendarAlt} />
      <Filters>
        <InputGroup>
          <Input
            control={control}
            type="select"
            values={anos}
            name="ano"
            placeholder="Ano"
          />
        </InputGroup>
        <InputGroup>
          <Input
            control={control}
            type="select"
            values={meses}
            name="mesinicial"
            placeholder="Mês inicial"
          />
        </InputGroup>
        <InputGroup>
          <Input
            control={control}
            type="select"
            values={meses}
            name="mesfinal"
            placeholder="Mês final"
          />
        </InputGroup>
        <InputGroup>
          <Button disabled={loading} onClick={handleSubmit(submitForm)}>
            CONSULTAR
          </Button>
        </InputGroup>
      </Filters>
      {noData && <SubTitle icon={FaUser} text="Não foram encontrados registros com os parâmetros informados." />}
    </Container>
  );
}

export default PurchasesStatement;
