import React, { useEffect, useState } from "react";
import { FaCommentAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Notice from "./Notice";
import BannerCapulImage from "../../assets/images/banner-capul.png";
import BannerAttImage from "../../assets/images/banner-cadastral.png";

import {
  CapulImg,
  Container,
  Content,
  P,
  RegistrationImg,
  Side
} from "./styles";
import { Link } from "react-router-dom";
import { apiLogin } from "../../services/api";
import Loading from "../../components/Loading";

interface HomeProps {}

function Home(props: RouteComponentProps<HomeProps>) {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  async function fetchPosts() {
    setLoading(true);
    const res = await apiLogin.get<any>("post");
    setPosts(res.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    
    <Container>
      <Content>
        <PageHeader title="Página Principal" hasPath={false} />

        <P>Ol&aacute;, usu&aacute;rio! Seja bem-vindo ao portal da Capul.</P>
        <P>
          Aqui voc&ecirc; ter&aacute; acesso a informa&ccedil;&otilde;es de seu
          interesse:
        </P>
        <P>&bull; Linha de Cr&eacute;dito</P>
        <P>&bull; Cart&otilde;es Ativos</P>
        <P>&bull; Compras Realizadas</P>
        <P>&bull; Contas a Pagar</P>
        <P>&bull; Contas a Receber</P>
        <P>&bull; Extratos de Fornecimento de Leite</P>
        <P>&bull; Extratos Financeiros</P>
        <P>&bull; Notifica&ccedil;&otilde;es Peri&oacute;dicas</P>
        <P>&bull; Capital Social</P>
        <P>
          <br />
        </P>
        <P>
          Para acessar basta navegar no menu a esquerda. Aqui est&atilde;o
          dispon&iacute;veis os acessos pertinentes ao seu perfil.
        </P>
        <P>
          <br />
        </P>

        <SubTitle text="Avisos e Notificações" icon={FaCommentAlt} />
        <Card>
          {loading ? (
            <Loading />
          ) : (
            <>
              {posts.map((notice) => (
                <Notice
                  title={notice.title}
                  date={new Date(notice.created_at)}
                  content={notice.content}
                />
              ))}
            </>
          )}
        </Card>
      </Content>
      <Side>
        <CapulImg src={BannerCapulImage} />
        <Link to="/atualizacao-de-cadastro">
          <RegistrationImg src={BannerAttImage} />
        </Link>
      </Side>
    </Container>
  );
}

export default Home;
