export const theme = {
  colors: {
    green50: '#30593A',
    green60: '#43684C',
    green80: '#5DB075',
    green90: '#A8CE45',

    grey100: '#BDC5CD',
    grey80: '#E5E5E5',
    grey50: '#F3F3F3',

    white: '#FFFFFF',
    danger: '#E10000'
  },

  fonts: {
    text400: 'MavenPro_400Regular',
    text500: 'MavenPro_500Medium',
    text600: 'MavenPro_600SemiBold',
    text700: 'MavenPro_700Bold'
  }
};