import styled from "styled-components";
import {devicesMax, devicesMin} from "../../utils/responsive"

export const Container = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tr {
    height: 48px;
    border-bottom: 1px solid #C4C4C4;
    &:nth-child(2n) {
      background-color: #F9F9F9;
    }
  }

  .bgBlue {
    background-color: #0B79CC;
    color: #FFFFFF
  }

  td,th {
    padding: 0 10px;
    font-size: 1.5rem;

  

    @media ${devicesMax.mobileL || devicesMin.tablet} {
      font-size: 1.2rem;
    }
    @media ${devicesMax.mobileM} {
      font-size: 1.1rem;
    }
    @media ${devicesMax.mobileS} {
      font-size: 1rem;
    }
  }

  

`;
