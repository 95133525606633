import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import queryString from 'query-string';
import { FaArrowLeft, FaUser } from "react-icons/fa";

import {
  Container,
  InputGroup,
  LoginCard,
  Title,
  Header,
} from "./styles";
import SubTitle from "../../components/SubTitle";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiLogin } from "../../services/api";

interface ForgotPasswordProps {}
interface FormData {
  login: string;
  password: string;
}

function ForgotPassword({}: ForgotPasswordProps) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const submitForgotPassword = async (form: any) => {
    const params = queryString.parse(location.search)
    console.log(form)
    try {
      setLoading(true);
      await apiLogin.post("/forgotPasswordUpdate", { password: form.password, token: params.token });
      alert("Senha alterada");
      history.push("login");
    } catch (error) {
      alert("Algo deu errado na alteração da senha");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <LoginCard>
        <Header>
          <Title>Esqueci a senha</Title>
        </Header>
        <>
          <SubTitle
            text="Digite a nova senha"
            icon={FaUser}
          />
          <InputGroup>
            <Input
              control={control}
              name="password"
              label="Nova senha"
              error={errors.email && errors.email.message}
            />
          </InputGroup>
          <Button
            onClick={handleSubmit(submitForgotPassword)}
            disabled={loading}
          >
            {loading ? <Loading /> : "Enviar"}
          </Button>
        </>
      </LoginCard>
    </Container>
  );
}

export default ForgotPassword;
