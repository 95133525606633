import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { ThemeProvider } from "styled-components";

import { theme } from "./styles/theme";
import { AuthProvider } from './hooks/auth';
import Routes from './routes';
import GlobalStyle from './styles/global';

function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['PT Sans']
      }
    });
   }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Routes />
        <GlobalStyle />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
