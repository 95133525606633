import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";

import {
  Container,
  FlexDiv,
  IconMenu,
  Title,
  UserInfo,
  UserMore,
  UserText,
} from "./styles";
import { FaBars, FaChevronDown } from "react-icons/fa";
import { useHistory, useLocation } from "react-router";
import { api } from "../../services/api";
interface HeaderProps { }

function Header({ }: HeaderProps) {
  const { user, toggleMenu, acessos } = useAuth();
  
  const history = useHistory();

  function handleSelectUser() {
    history.push("usuarios");
  }

  return (
    <Container>
      <FlexDiv className="button-title">
        <IconMenu onClick={toggleMenu}>
          <FaBars color="#FFF" size={30} />
        </IconMenu>
        <Title>Portal da Capul</Title>
      </FlexDiv>
      <FlexDiv>
        <UserInfo>
          <UserInfo className="div-info">
            <UserText>
                Usuário: {user.nome} {user.nomedependente && <>({user.nomedependente.trim()})</>}.
              </UserText>
              <UserText>
                Código: {user.matricula}
              </UserText>
            </UserInfo>
          {!!acessos?.acessos?.[0]?.associado.length && (
            <UserMore onClick={handleSelectUser}>
              <FaChevronDown color="#FFF" size={20} />
            </UserMore>
          )}
        </UserInfo>
      </FlexDiv>
    </Container>
  );
}

export default Header;
