import React, { HTMLProps, ReactNode } from 'react';

import { Container, Text } from './styles';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
}

function Button({ children, onClick, disabled = false, style }: ButtonProps) {
  return (
    <Container onClick={onClick} disabled={disabled} style={style}>
      <Text>{children}</Text>
    </Container>
  );
};

export default Button;
