import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { DateInfo } from "../ShareCapital/styles";

import { Container } from "./styles";

interface ActiveCardsProps {}

function ActiveCards({}: RouteComponentProps<ActiveCardsProps>) {
  const { user } = useAuth();
  const [cartoesativos, setCartoesativos] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get("getCartoesAtivos", {
        params: {
          CODCLIENTE: user.matricula,
        },
      })
      .then((res: any) => {
        setCartoesativos(res.data.cartoesativos);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.matricula]);

  return (
    <Container>
      <PageHeader title="Cartões Ativos" />

      <SubTitle text={`Total de cartões: ${cartoesativos.length}`} icon={FaCalendarAlt} />
      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>
            <Table>
              <tr>
                <th>Nome</th>
                <th>Perfil do Tipo</th>
                <th>Número</th>
              </tr>
              {cartoesativos.map((cartao) => (
                <tr>
                  <td>{cartao.nome}</td>
                  <td>{cartao.tipo}</td>
                  <td>{cartao.numero}</td>
                </tr>
              ))}
            </Table>
          </>
        )}
      </Card>
    </Container>
  );
}

export default ActiveCards;
