import React, { createContext, useContext, useState, ReactNode } from "react";

import { api, apiLogin } from "../services/api";
import { COLLECTION_ACCESS, COLLECTION_CARTAO, COLLECTION_USERS } from "../configs/database";
import { useEffect } from "react";
import { useWindowDimensions, sizesNumber } from "../utils/responsive";

type User = {
  matricula: string;
  nome?: string;
  email?: string;
  nomedependente?: string;
  permissao?: string;
  permissoes?: Array<any>;
  loja?: string;
  admin?: boolean;
  titular: "N" | "S";
  firstAccess: boolean;

};

type AuthContextData = {
  user: User;
  cartao: string;
  loading: boolean;
  isOpenMenu: boolean;
  acessos: ResAcessos;
  toggleMenu: () => void;
  getAccess: (numCartao: string, matricula: string) => Promise<void>;
  changeUser: (associado: User) => void;
  attEmail: (email: string) => void;
  signIn: (login: string, password: string, matricula: string) => Promise<void>;
  signOut: () => Promise<void>;
  disableFirstAccess: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

interface ResAcessos {
  cartao: string;
  nomedependente: string;
  tipodependente: string;
  acessos: Acesso[];
}

interface Acesso {
  associado: Associado[];
}

interface Associado {
  matricula: string;
  loja: string;
  nome: string;
  permissoes: Permissao[];
}

interface Permissao {
  descricao: string;
  permissao: string;
  codigo: string;
}

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({} as User);
  const [cartao, setCartao] = useState('');
  const [acessos, setAcessos] = useState({} as ResAcessos);
  const [loading, setLoading] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const {width} = useWindowDimensions(); 
  const [firstResponsiveMenu, setfirstResponsiveMenu] = useState(true);
  useEffect(() => {
    loadUserStorageData();
  }, []);

  useEffect(() => {
    if( (width <= sizesNumber.tablet)){
      if(isOpenMenu ) setIsOpenMenu(false);
      setfirstResponsiveMenu(false)
    } 

  }, [width]);

  async function loadUserStorageData() {
    const storageUser = localStorage.getItem(COLLECTION_USERS);
    const storageCartao = localStorage.getItem(COLLECTION_CARTAO);
    const storageAcessos = localStorage.getItem(COLLECTION_ACCESS);

    if (storageUser) {
      const userLogged = JSON.parse(storageUser) as User;
      const userCartao = storageCartao;

      if (storageAcessos) {
        const userAccess = JSON.parse(storageAcessos) as ResAcessos;
        setAcessos(userAccess);
      }
      // api.defaults.headers.authorization = `Bearer ${userLogged.tokenCompany}`;

      setUser(userLogged);
      setCartao(userCartao);
    }
  }

  async function signIn(login: string, password: string, matricula: string) {
    try {
      setLoading(true);
      const res = await apiLogin.post<User>("login", {numcartao: login, password: password});
      setUser(res.data)
      localStorage.setItem(COLLECTION_USERS, JSON.stringify(res.data))
      setCartao(login)
      localStorage.setItem(COLLECTION_CARTAO, login)
      if (res.data.titular !== 'S'){
        await getAccess(login, matricula)
      }
    } catch (e) {
      throw new Error("Erro ao realizar login, confira sua senha e tente novamente.");
    } finally {
      setLoading(false);
    }
  }

  function changeUser(associado) {
    const storageUser = localStorage.getItem(COLLECTION_USERS);
    const userLogged = JSON.parse(storageUser) as User;
    const newUser = {...userLogged, matricula: associado.matricula, loja: associado.loja, nome: associado.nome ? associado.nome : user.nome, permissoes: associado.permissoes}
    setUser(newUser)
    localStorage.setItem(COLLECTION_USERS, JSON.stringify(newUser))
  }

  function attEmail(email) {
    const storageUser = localStorage.getItem(COLLECTION_USERS);
    const userLogged = JSON.parse(storageUser) as User;
    const newUser = {...userLogged, email}
    setUser(newUser)
    localStorage.setItem(COLLECTION_USERS, JSON.stringify(newUser))
  }

  async function getAccess(numCartao, matricula) {
    try {
      setLoading(true);
      const res = await api.get<ResAcessos>("getAcessos", {params: { NUMCARTAO: numCartao.trim() }});
      setAcessos(res.data)
      localStorage.setItem(COLLECTION_ACCESS, JSON.stringify(res.data))
      const userDefault = res.data.acessos[0].associado.find(acess => acess.matricula === matricula)
      changeUser(userDefault)
    } catch (e) {
      throw new Error("Erro ao carregar acessos");
    } finally {
      setLoading(false);
    }
  }

  function disableFirstAccess() {
    const newUser = {...user, firstAccess: false}
    setUser(newUser)
    localStorage.setItem(COLLECTION_USERS, JSON.stringify(newUser))
  }

  async function signOut() {
    setUser({} as User);
    setCartao('');
    setAcessos({} as ResAcessos);
    localStorage.removeItem(COLLECTION_USERS);
    localStorage.removeItem(COLLECTION_CARTAO);
    localStorage.removeItem(COLLECTION_ACCESS);
  }

  function toggleMenu() {
    if(!firstResponsiveMenu || (width >= sizesNumber.tablet)) setIsOpenMenu(!isOpenMenu);
  }

  return (
    <AuthContext.Provider
      value={{ user, cartao, loading, isOpenMenu, acessos, toggleMenu, signIn, signOut, changeUser, getAccess, disableFirstAccess,attEmail }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
