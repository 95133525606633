import { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { getDateFromString } from "../../utils/date";
import { Container, DateInfo, Filters, InputGroup } from "./styles";
import jsPDF from "jspdf";
import  autoTable  from "jspdf-autotable";
import Button from "../../components/Form/Button";



interface ShareCapitalProps {}

function ShareCapital({}: RouteComponentProps<ShareCapitalProps>) {
  const { user } = useAuth();
  const [capsocial, setCapsocial] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const date = new Date();
    api
      .get("getCapSocial", {
        params: {
          CODCLIENTE: user.matricula,
          EMISDE: "20000101",
          EMISATE: "20501231",
        },
      })
      .then((res: any) => {
        setCapsocial(res.data.capsocial);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.matricula]);

  function exportPDF() {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Capital Social";
    const data_emissao = new Date();
    const dia = data_emissao.getDate();
    const mes = data_emissao.getMonth();
    const ano = data_emissao.getFullYear() % 2000;
    const headers = [["Data", "Historico", "Valor Operacao", " D/C", "Subscrito", "A integralizar", "Integralizado"]];

    const data =  capsocial.map(
        capital=> [capital.datamovimentacao, capital.historico, capital.valoroperacao, capital.tipo, 
        capital.capitalsubscrito.toLocaleString("default",{style: "currency", currency: "BRL",}),
        capital.capitalintegralizar.toLocaleString("default", {style: "currency",currency: "BRL",}),
        capital.capitalintegralizado.toLocaleString("default", {style: "currency",currency: "BRL",})]
        );

    doc.setFont('PT Sans', 'sans-serif');

    doc.text(title, 250 , 30);


    autoTable(doc, {
      head: headers,
      body: data
    })
    doc.save(`Capital-Social (${dia}-${mes}-${ano}).pdf`)
  }

  return (
    <Container>
      <PageHeader title="Capital Social" />

      <SubTitle
        text={`Quantidade de Registros: ${capsocial.length}`}
        icon={FaCalendarAlt}
      />
      <Filters>
       <InputGroup>
          <Button onClick={exportPDF}>Exportar PDF</Button>
        </InputGroup>
      </Filters>
      
      <Card>
        {loading ? (
          <Loading />
        ) : (
          <>
            <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>
            <Container className="div-table">
            <Table >
               <tr>
                <th>Data</th>
                <th>Histórico</th>
                <th>Valor Operação</th>
                <th>D/C</th>
                <th>Subscrito</th>
                <th>A Integralizar</th>
                <th>Integralizado</th>
              </tr>
              {capsocial.map((capital) => (
                <tr>
                  <td>
                    {getDateFromString(capital.datamovimentacao).toLocaleString(
                      "default",
                      { year: "numeric", month: "numeric", day: "numeric" }
                    )}
                  </td>
                  <td>{capital.historico}</td>
                  <td>{capital.valoroperacao}</td>
                  <td>{capital.tipo}</td>
                  <td>
                    {capital.capitalsubscrito.toLocaleString("default", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    {capital.capitalintegralizar.toLocaleString("default", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    <b>
                      {capital.capitalintegralizado.toLocaleString("default", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </b>
                  </td>
                </tr>
              ))}
            </Table>
            </Container>
            
          </>
        )}
      </Card>
    </Container>
  );
}

export default ShareCapital;
