import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt, FaMapMarkerAlt,FaFilePdf } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { b64toBlob } from "../../utils/base62";
import { getDateFromString, getStringFromDateString } from "../../utils/date";
import { DateInfo } from "../ShareCapital/styles";

import { Container, Filters, InputGroup, Td } from "./styles";

interface BillsToPayProps {}

function BillsToPay({}: RouteComponentProps<BillsToPayProps>) {

  

  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [contaspagar, setContaspagar] = useState([]);
  const [boleto, setBoleto] = useState([]);
  const [vencde, setVencde] = useState("");
  const [vencate, setVencate] = useState("");

  async function gerarBoleto(titulo, codloja, fil, prefixo, parcela, tipo) {
  
    let newTab = window.open();
    newTab.document.write("Carregando...");
    newTab.document.title = "Carregando...";
    try {
      const res = await api.get<any>("getBoleto", {
        params: {
          CODCLIENTE: user.matricula,
          TITULO: titulo,
          CODLOJA: codloja,
          FIL:fil,
          PREFIXO:prefixo,
          PARCELA:parcela,
          TIPO:tipo,

        },
      });
  
      
      if (!res.data.pdf) throw new Error("Erro ao requisitar pdf");
      const blob = b64toBlob(res.data.pdf, "application/pdf");
      const url = URL.createObjectURL(blob);
      newTab.location.href = url;
    } catch (err) {
      newTab.close();
    } finally {
      
    }

    return () => { // cleanup function of type : () => void
      console.log("Cleanup")
    }
    
  }


  useEffect(() => {
    if (vencde && vencate) {
      setLoading(true);
      api
        .get("getCPagar", {
          params: {
            CODCLIENTE: user.matricula,
            VENCDE: vencde,
            VENCATE: vencate,
          },
        })
        .then((res: any) => {
          setContaspagar(res.data.contaspagar);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user.matricula, vencde, vencate]);

  function submitForm(form) {
    const vencde = getStringFromDateString(form.vencde);
    const vencate = getStringFromDateString(form.vencate);
    setVencde(vencde);
    setVencate(vencate);
  }

  return (
    <Container>
      <PageHeader title="Contas a Pagar" />

      <SubTitle text="Informe o período:" icon={FaCalendarAlt} />
      <Filters>
        <InputGroup>
          <Input
            control={control}
            mask="99/99/9999"
            name="vencde"
            placeholder="Data Inicial"
          />
        </InputGroup>
        <InputGroup>
          <Input
            control={control}
            mask="99/99/9999"
            name="vencate"
            placeholder="Data Final"
          />
        </InputGroup>
        <InputGroup>
          <Button onClick={handleSubmit(submitForm)}>CONSULTAR</Button>
        </InputGroup>
      </Filters>

      {loading ? (
        <Loading />
      ) : (
        contaspagar.length ? (
          <Card>
            <>
              <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>

              <SubTitle
                text={`Quantidade de Registros listados: ${contaspagar.length}`}
                sub
              />
              <Container className="div-table">
              <Table>
                <tr>
                  <th>Filial</th>
                  <th>Loja</th>
                  <th>Emissão</th>
                  <th>Número</th>
                  <th>Parc/Qtd</th>
                  <th>Saldo</th>
                  <th>Tipo</th>
                  <th>Vencimento</th>
                  <th>Valor Título</th>
                  <th>Boleto</th>
                  <th>Extrato de Fechamento</th>
                </tr>
                {contaspagar.map((conta) => (
                  <tr>
                    <td>{conta.filial}</td>
                    <td>{conta.loja}</td>
                    <td>
                      {getDateFromString(conta.emissao).toLocaleString(
                        "default",
                        { year: "numeric", month: "numeric", day: "numeric" }
                      )}
                    </td>
                    <td>{conta.numero}</td>
                    <td>{conta.parcela}</td>
                    <td>{conta.saldo}</td>
                    <td>{conta.tipo}</td>
                    <td>
                      {getDateFromString(conta.vencimento).toLocaleString(
                        "default",
                        { year: "numeric", month: "numeric", day: "numeric" }
                      )}
                    </td>
                    <td>{conta.valor}</td>
                    <td>
                    {conta.boleto == "S" ?
                      <button onClick={() => gerarBoleto(conta.numero,conta.loja,conta.filial,conta.prefixo,conta.parcela,conta.tipo)}>
                        <FaFilePdf />
                      </button> : conta.boleto
                    }
                  </td>
                   
                    <td>{conta.fechamento}</td>                   
                  </tr>
                ))}
                <tr>
                  <Td
                    colSpan={11}
                    className="bgBlue"
                  >
                    Soma total:{" "}
                    <b>
                      R${" "}
                      {
                        +contaspagar
                          .map((conta) => conta.valor)
                          .reduce((soma, i) => soma + i, 0)
                          .toFixed(2)
                      }
                    </b>
                  </Td>
                </tr>
              </Table>
              </Container>
              
            </>
          </Card>
        ) : <SubTitle text="Nenhum dado encontrado para essa data." icon={FaMapMarkerAlt} />
      )}
    </Container>
  );
}

export default BillsToPay;
