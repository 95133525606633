import { type } from "os";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";

import {
  Container,
  Error,
  InputCurrencyText,
  InputMaskedText,
  InputSelect,
  InputText,
  InputTextArea,
  Label,
} from "./styles";

interface InputProps {
  label?: string;
  control: Control;
  name: string;
  error?: string;
  placeholder?: string;
  defaultValue?: string;
  type?: "simple" | "currency" | "select" | "mask" | "text";
  disabled?: boolean;
  values?: Options[];
  mask?: string;
  rows?: number;
  password?: boolean;
}

type Options = {
  value: string;
  label: string;
};

function Input({
  label,
  name,
  control,
  error,
  defaultValue,
  disabled,
  placeholder,
  values = [],
  mask,
  type = "simple",
  password,
  ...rest
}: InputProps) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          if (type === "currency") {
            return (
              <CurrencyFormat
                onChange={onChange}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
              />
            );
          }
          if (type === "select") {
            return (
              <SelectInput
                onChange={onChange}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                values={values}
                {...rest}
              />
            );
          }
          if (type === "text") {
            return (
              <InputTextArea
                onChange={onChange}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                {...rest}
              />
            );
          }
          if (mask) {
            return (
              <MaskedInput
                onChange={onChange}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                mask={mask}
                {...rest}
              />
            );
          }
          return (
            <SimpleInput
              onChange={onChange}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              password={password}
              {...rest}
            />
          );
        }}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}

const SimpleInput = ({ onChange, value, password, ...rest }) => {
  return <InputText onChange={onChange} value={value} type={password && "password"} {...rest} />;
};

const MaskedInput = ({ onChange, value, mask, ...rest }) => {
  return (
    <InputMaskedText mask={mask} onChange={onChange} value={value} {...rest} />
  );
};

const SelectInput = ({ onChange, value, values, placeholder, ...rest }) => {
  return (
    <InputSelect onChange={onChange} value={value} {...rest}>
      <option>{placeholder || "Selecione um Valor"}</option>
      {values.map((value) => (
        <option value={value.value}>{value.label}</option>
      ))}
    </InputSelect>
  );
};

const CurrencyFormat = ({ onChange, value, ...rest }) => {
  const [currency, setCurrency] = useState(value / 100);
  return (
    <InputCurrencyText
      {...rest}
      value={currency}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={2}
      onValueChange={(target) => {
        setCurrency(target.floatValue);
        onChange(target.floatValue * 100);
      }}
      isNumericString
      prefix="R$ "
    />
  );
};

export default Input;
