import styled from 'styled-components';
import { devicesMax } from "../../utils/responsive"

export const Container = styled.div`
  margin: 38px 45px;
  width: 100%;

  @media ${devicesMax.tablet} {
    margin: 20px 20px;
  }
`;
