import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import Modal from "react-modal";
import Card from "../../components/Card";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loading";
import PageHeader from "../../components/PageHeader";
import SubTitle from "../../components/SubTitle";
import Table from "../../components/Table";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { getDateFromString } from "../../utils/date";
import { Filters, InputGroup } from "../BillsToPay/styles";
import { DateInfo } from "../ShareCapital/styles";

import { Container, Content, File, Files, Td, Title } from "./styles";
import { anos, meses } from "../../utils/datas";
import { b64toBlob } from "../../utils/base62";

interface PurchasesMadeProps {}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80%"
  },
};

function PurchasesMade({}: RouteComponentProps<PurchasesMadeProps>) {
  const { user } = useAuth();
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [compras, setCompras] = useState<any>([]);
  const [qtyCompras, setQtyCompras] = useState(0);
  const [totalGeral, setTotalGeral] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [compraSelected, setCompraSelected] = useState(null);
  const [loadingItens, setLoadingItens] = useState(false);
  const [itens, setItens] = useState([]);
  const [danfe, setDanfe] = useState<any>({});
  const [devolucoes, setDevolucoes] = useState<any>([]);
  const [loadingDanfe, setLoadingDanfe] = useState(false);
  const [loadingDevolucoes, setLoadingDevolucoes] = useState(false);
  const [ano, setAno] = useState("");
  const [mesInicial, setMesInicial] = useState("");
  const [mesFinal, setMesFinal] = useState("");

  useEffect(() => {
    if (ano && mesInicial && mesFinal) {
      setLoading(true);
      api
        .get("getExtConsumo", {
          params: {
            CODCLIENTE: user.matricula,
            ANO: ano,
            MESDE: mesInicial,
            MESATE: mesFinal,
          },
        })
        .then((res: any) => {
          let helper = {};
          let total = 0;
          res.data.compras?.forEach((compra) => {
            total += compra.valor;
            if (helper[compra.desccondpg]) {
              helper[compra.desccondpg].push(compra);
            } else {
              helper[compra.desccondpg] = [];
              helper[compra.desccondpg].push(compra);
            }
          });
          setCompras(helper);
          setQtyCompras(res.data.compras.length);
          setTotalGeral(+total.toFixed(2));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user.matricula, ano, mesInicial, mesFinal]);

  useEffect(() => {
    if (compraSelected) {
      setLoadingItens(true);
      api
        .get("getItensExtConsumo", {
          params: {
            CODCLIENTE: user.matricula,
            FIL: compraSelected.filial,
            CODLOJA: compraSelected.loja,
            NOTA: compraSelected.documento,
            SERIE: compraSelected.serie,
          },
        })
        .then((res: any) => {
          setItens(res.data.itens);
        })
        .finally(() => {
          setLoadingItens(false);
        });
    }
  }, [user.matricula, compraSelected]);

  useEffect(() => {
    if (compraSelected) {
      setLoadingDevolucoes(true);
      setLoadingDanfe(true);
      api
        .get("getDanfe", {
          params: {
            CODCLIENTE: user.matricula,
            FIL: compraSelected.filial,
            CODLOJA: compraSelected.loja,
            NOTA: compraSelected.documento,
            SERIE: compraSelected.serie,
          },
        })
        .then((res: any) => {
          setDanfe(res.data.notavenda);
        })
        .finally(() => {
          setLoadingDanfe(false);
        });
      api
        .get("getDevDanfe", {
          params: {
            CODCLIENTE: user.matricula,
            FIL: compraSelected.filial,
            CODLOJA: compraSelected.loja,
            NOTA: compraSelected.documento,
            SERIE: compraSelected.serie,
          },
        })
        .then((res: any) => {
          setDevolucoes(res.data.devolucoes);
        })
        .finally(() => {
          setLoadingDevolucoes(false);
        });
    }
  }, [user.matricula, compraSelected]);

  function handleGetPdfDanfe(pdf) {
    let newTab = window.open();
    newTab.document.write("Carregando...");
    newTab.document.title = "Carregando...";
    const blob = b64toBlob(pdf, "application/pdf");
    const url = URL.createObjectURL(blob);
    newTab.location.href = url;
  }

  function submitForm(form) {
    setAno(form.ano);
    setMesInicial(form.mesinicial);
    setMesFinal(form.mesfinal);
  }

  function handleSelectCompra(compra) {
    setCompraSelected(compra);
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
    setDevolucoes([]);
    setDanfe({})
  }

  return (
    <>
      <Container>
        <PageHeader title="Compras Realizadas" />

        <SubTitle text="Informe o período:" icon={FaCalendarAlt} />
        <Filters>
          <InputGroup>
            <Input
              control={control}
              type="select"
              values={anos}
              name="ano"
              placeholder="Ano"
            />
          </InputGroup>
          <InputGroup>
            <Input
              control={control}
              type="select"
              values={meses}
              name="mesinicial"
              placeholder="Mês inicial"
            />
          </InputGroup>
          <InputGroup>
            <Input
              control={control}
              type="select"
              values={meses}
              name="mesfinal"
              placeholder="Mês final"
            />
          </InputGroup>
          <InputGroup>
            <Button onClick={handleSubmit(submitForm)}>CONSULTAR</Button>
          </InputGroup>
        </Filters>

        <Card>
          {loading ? (
            <Loading />
          ) : (
            <>
              <DateInfo>Emitido em: {new Date().toLocaleString()}</DateInfo>

              <SubTitle
                text={`Quantidade de Registros: ${qtyCompras}`}
                sub
              />
              {Object.entries(compras).map(([key, value]: any) => {
                let total = 0;
                return (
                  <Container className="div-table">
 <Table style={{ marginBottom: "25px" }}>
                    <tr>
                      <th>Data</th>
                      <th>Espécie</th>
                      <th>Documento</th>
                      <th>Forma Pagto.</th>
                      <th>Valor</th>
                      <th>DANFE</th>
                      <th>Devolução</th>
                    </tr>
                    {value.map((compra) => {
                      total += compra.valor;
                      return (
                        <tr
                          onClick={() => handleSelectCompra(compra)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {getDateFromString(compra.emissao).toLocaleString(
                              "default",
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td>{compra.especie}</td>
                          <td>{compra.documento}</td>
                          <td>{compra.desccondpg}</td>
                          <td>{compra.valor}</td>
                          <td>{compra.danfe}</td>
                          <td>{compra.devolucao}</td>
                        </tr>
                      );
                    })}
                    <tr className="bgBlue">
                      <Td
                        className="bgBlue"
                        colSpan={11}
                        
                      >
                        Total tipo {key}: <b>R$ {+total.toFixed(2)}</b>
                      </Td>
                    </tr>
                  </Table>
                  </Container>
                 
                );
              })}
              <Table>
                <tr>
                  <Td colSpan={11}>
                    Total do Extrato de Consumo: <b>R$ {totalGeral}</b>
                  </Td>
                </tr>
              </Table>
            </>
          )}
        </Card>
      </Container>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Itens da compra"
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        {loadingItens ? (
          <Loading />
        ) : (
          <>
            <Table>
              <tr className="bgBlue">
                <th className="bgBlue">Produtos</th>
                <th className="bgBlue">Descrição</th>
                <th className="bgBlue">Quantidade</th>
                <th className="bgBlue">Valor Unitário</th>
                <th className="bgBlue">Valor Total</th>
              </tr>
              {itens.map((item) => (
                <tr>
                  <td>{item.cod}</td>
                  <td>{item.desc}</td>
                  <td>{item.quant}</td>
                  <td>{item.prcven}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </Table>
            <Files>
              {loadingDanfe ? (
                <Loading />
              ) : (
                <>
                  {danfe?.pdf && (
                    <File>
                      <Title>Danfe:</Title>
                      <Content onClick={() => handleGetPdfDanfe(danfe?.pdf)}>
                        Arquivo 1
                      </Content>
                    </File>
                  )}
                </>
              )}
              {loadingDevolucoes ? (
                <Loading />
              ) : (
                <>
                  {!!devolucoes.length && (
                    <File>
                      <Title>Devolucao:</Title>
                      {devolucoes?.map((devolucao, i) => (
                        <Content
                          onClick={() => handleGetPdfDanfe(devolucao?.pdf)}
                        >
                          Arquivo {i + 1}
                        </Content>
                      ))}
                    </File>
                  )}
                </>
              )}
            </Files>
          </>
        )}
      </Modal>
    </>
  );
}

export default PurchasesMade;
